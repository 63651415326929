<template>
  <b-card no-body>
    <b-card-header>
      <b-card-title>{{ reportType.name }}</b-card-title>
      <b-button
        v-b-tooltip.hover.v-success
        variant="success"
        class="btn-icon ml-50"
        title="Xuất dữ liệu"
        @click="exportData"
      >
        <feather-icon icon="DownloadIcon" />
      </b-button>
    </b-card-header>
    <b-card-body>
      <b-row>
        <b-col>
          <select-report-type
            :label="null"
            v-model="reportType"
            :default-value="reportType"
          />
        </b-col>
        <b-col>
          <select-school
            :label="null"
            v-model="school"
            :default-value="school"
          />
        </b-col>

        <b-col>
          <select-month v-model="month" :default-value="month" />
        </b-col>
      </b-row>
      <b-row v-if="htmlContent">
        <b-col>
          <div v-html="htmlContent"></div>
        </b-col>
      </b-row>
    </b-card-body>
  </b-card>
</template>

<script>
import {
  BCard,
  BCardHeader,
  BCardTitle,
  BCardBody,
  BRow,
  BCol,
  BLink,
  BPagination,
  BFormSelect,
  BSpinner,
  BBadge,
  BButton,
  VBModal,
  VBTooltip,
  BFormInput,
} from "bootstrap-vue";
import SelectMonth from "@/views/components/SelectMonth.vue";
import SelectSchool from "@/views/components/SelectSchool.vue";
import useReportByServiceType from "./useReportByServiceType";
import SelectReportType from "@/views/components/SelectReportType.vue";
export default {
  components: {
    BCard,
    BCardHeader,
    BCardTitle,
    BCardBody,
    BRow,
    BCol,
    BLink,
    BPagination,
    BFormSelect,
    BSpinner,
    BBadge,
    BButton,
    BFormInput,
    SelectMonth,
    SelectSchool,
    SelectReportType,
  },
  directives: {
    "b-modal": VBModal,
    "b-tooltip": VBTooltip,
  },
  setup() {
    const { school, month, fetchData, htmlContent, reportType, exportData } =
      useReportByServiceType();
    return { school, month, fetchData, htmlContent, reportType, exportData };
  },

  created() {
    this.fetchData();
  },
};
</script>
